import { apiInstance,  } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const dashboardJson = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_DASHBOARD, payload);
  return data;
};

export const dashboardExel = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_DASHBOARD_EXPORT, payload);
  return data;
};

export const solicitacoesExel = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_SOLICITACOES_EXPORT, payload);
  return data;
};

export const atendimentosMedicopArovadorJson = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_ATENDIMENTOS_MEDICO_APROVADOR, payload);
  return data;
};

export const atendimentosMedicopArovadorExel = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_ATENDIMENTOS_MEDICO_APROVADOR_EXEL, payload);
  return data;
};

export const atendimentosColaboradorJson = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_ATENDIMENTOS_COLABORADOR, payload);
  return data;
};

export const atendimentosColaboradorExel = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_ATENDIMENTOS_COLABORADOR_EXEL, payload);
  return data;
};

export const solicitacoesPorProdutoJson = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_SOLICITACOES_POR_PRODUTO, payload);
  return data;
};

export const solicitacoesPorProdutoExel = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_SOLICITACOES_POR_PRODUTO_EXEL, payload);
  return data;
};

export const solicitacoesPorLinhaJson = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_SOLICITACOES_POR_LINHA, payload);
  return data;
};

export const solicitacoesPorPLinhaExel = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.RELATORIO_SOLICITACOES_POR_LINHA_EXEL, payload);
  return data;
};